import React, { useEffect, useState } from 'react';
import axios from 'axios';
import logo from './assets/images/aa_logo_lg_white.svg';
import './assets/styles/style.scss';
import './assets/script/custom.js';
const MonthlyForecastHtml = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Add the body-background class when the component mounts
    document.body.classList.add('body-background');

    // Fetch data from the API
    axios.get('https://content.astrologyanswers.com/api/monthly-forecast/2024-05-03?token=319a261b23af5ad0eac0e16fead66216&populate=*')
      .then(response => {
        setData(response.data);
        setLoading(false);
      })
      .catch(error => {
        setError(error);
        setLoading(false);
      });

    // Remove the body-background class when the component unmounts
    return () => {
      document.body.classList.remove('body-background');
    };
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  if (!data) {
    return null;
  }

  const { attributes } = data.data;
  const { monthlyForecast = [] } = attributes;

  return (
    <div className="App">
      <header className="mf-header">
        <div className='container'>
          <div className='logo'>
            <img src={logo} className="" alt="Astrology Answers" />
          </div>
          <h1>
            Welcome to Your<br /> Monthly Forecast
            <span className='mf-current-month'>May 2024</span>
          </h1>
        </div>
      </header>

      <main>
        <div className='container main-section'>
          <div className='dawn-message'>
            <div className='dawn-img'>
              <img src={require('./assets/images/dawn-circle-2.png')} alt="" />
            </div>
            <div className='dawn-msg'>
              <p><strong>Namaste!</strong></p>
              <div dangerouslySetInnerHTML={{ __html: monthlyForecast[0]?.Content }}></div>
            </div>
            <div className='clearfix'></div>
          </div>

          <div className='describe-month'>
            <div dangerouslySetInnerHTML={{ __html: monthlyForecast[1]?.Content }}></div>
          </div>

          <div className='Transits-month'>
            <h2>Important Transits in May 2024</h2>
            <div className='text-center'>
              <img src={require('./assets/images/border-01.png')} alt="" className='title2-border' />
            </div>
            <div className='transits-dates'>
              <div dangerouslySetInnerHTML={{ __html: monthlyForecast[2]?.Content }}></div>
            </div>
          </div>

          <div className='moon-month'>
            <h2>The Moon in May 2024</h2>
            <div className='text-center'>
              <img src={require('./assets/images/border-01.png')} alt="" className='title2-border' />
            </div>
            <div className='moon-dates'>
              <div dangerouslySetInnerHTML={{ __html: monthlyForecast[3]?.Content }}></div>
            </div>
          </div>

          <div className='all-signs'>
                  <div className='sign-nav'>
                      <ul>
                        <li><a href='javascript:void(0);' data-sign='aries' className='active'>Aries</a></li>
                        <li><a href='javascript:void(0);' data-sign='taurus'>Taurus</a></li>
                        <li><a href='javascript:void(0);' data-sign='gemini'>Gemini</a></li>
                        <li><a href='javascript:void(0);' data-sign='cancer'>Cancer</a></li>
                        <li><a href='javascript:void(0);' data-sign='leo'>Leo</a></li>
                        <li><a href='javascript:void(0);' data-sign='virgo'>Virgo</a></li>
                        <li><a href='javascript:void(0);' data-sign='libra'>Libra</a></li>
                        <li><a href='javascript:void(0);' data-sign='scorpio'>Scorpio</a></li>
                        <li><a href='javascript:void(0);' data-sign='sagittarius'>Sagittarius</a></li>
                        <li><a href='javascript:void(0);' data-sign='capricorn'>Capricorn</a></li>
                        <li><a href='javascript:void(0);' data-sign='aquarius'>Aquarius</a></li>
                        <li><a href='javascript:void(0);' data-sign='pisces'>Pisces</a></li>
                      </ul>
                  </div>
                  <div className='clearfix'></div>

                  <div className='sign-desc aries-desc'>
                    <h2>Aries</h2>
                    <div dangerouslySetInnerHTML={{ __html: monthlyForecast[4]?.Content }}></div>

                  </div>

                  <div className='sign-desc taurus-desc'>
                    <h2>Taurus</h2>
                    <div dangerouslySetInnerHTML={{ __html: monthlyForecast[5]?.Content }}></div>


                  </div>

                  <div className='sign-desc gemini-desc'>
                    <h2>Gemini</h2>
                    <div dangerouslySetInnerHTML={{ __html: monthlyForecast[6]?.Content }}></div>


                  </div>

                  <div className='sign-desc cancer-desc'>
                    <h2>Cancer</h2>
                    <div dangerouslySetInnerHTML={{ __html: monthlyForecast[7]?.Content }}></div>

                  </div>

                  <div className='sign-desc leo-desc'>
                    <h2>Leo</h2>
                    <div dangerouslySetInnerHTML={{ __html: monthlyForecast[8]?.Content }}></div>

                  </div>

                  <div className='sign-desc virgo-desc'>
                    <h2>Virgo</h2>
                    <div dangerouslySetInnerHTML={{ __html: monthlyForecast[9]?.Content }}></div>

                  </div>

                  <div className='sign-desc libra-desc'>
                    <h2>Libra</h2>
                    <div dangerouslySetInnerHTML={{ __html: monthlyForecast[10]?.Content }}></div>

                  </div>

                  <div className='sign-desc scorpio-desc'>
                    <h2>Scorpio</h2>
                    <div dangerouslySetInnerHTML={{ __html: monthlyForecast[11]?.Content }}></div>

                  </div>

                  <div className='sign-desc sagittarius-desc'>
                    <h2>Sagittarius</h2>
                    <div dangerouslySetInnerHTML={{ __html: monthlyForecast[12]?.Content }}></div>


                  </div>

                  <div className='sign-desc capricorn-desc'>
                    <h2>capricorn</h2>
                    <div dangerouslySetInnerHTML={{ __html: monthlyForecast[13]?.Content }}></div>

                  </div>

                  <div className='sign-desc aquarius-desc'>
                    <h2>aquarius</h2>
                    <div dangerouslySetInnerHTML={{ __html: monthlyForecast[14]?.Content }}></div>


                  </div>

                  <div className='sign-desc pisces-desc'>
                    <h2>pisces</h2>
                    <div dangerouslySetInnerHTML={{ __html: monthlyForecast[15]?.Content }}></div>

                  </div>

              </div>

          <div className='text-center'>
            <img src={require('./assets/images/border-01.png')} alt="" className='title2-border' />
          </div>
          <div className='clearfix'></div>
          <div className='dawn-sign'>
            <p><strong>Much love, my Dear...</strong><br /> Dawn</p>
            <img src={require('./assets/images/dawn_signature1.png')} alt="" />
            <p><strong><i>World-Renowned Astrologer</i></strong></p>
          </div>
        </div>
        <div className='clearfix'></div>
      </main>
    </div>
  );
};

export default MonthlyForecastHtml;
