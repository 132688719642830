import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import MonthlyForecastPDF from './MonthlyForecastPDF';
import MonthlyForecastHTML from './MonthlyForecastHTML';
import Home from './Home'; // Create a Home component if you don't have one

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/monthly-forecast/pdf" element={<MonthlyForecastPDF />} />
        <Route path="/monthly-forecast/html" element={<MonthlyForecastHTML />} />
      </Routes>
    </Router>
  );
};

export default App;
