document.addEventListener('DOMContentLoaded', function() {
    document.addEventListener('click', function(event) {
        if (event.target.matches('.sign-nav ul li a[data-sign]')) {
            var sign = event.target.getAttribute('data-sign');
            var descClass = '.sign-desc.' + sign + '-desc';

            // Remove 'active' class from all links
            document.querySelectorAll('.sign-nav ul li a').forEach(function(el) {
                el.classList.remove('active');
            });

            // Add 'active' class to the clicked link
            event.target.classList.add('active');

            // Hide all description elements
            document.querySelectorAll('.sign-desc').forEach(function(el) {
                el.style.display = 'none';
            });

            // Show the specific description element
            var descElement = document.querySelector(descClass);
            if (descElement) {
                descElement.style.display = 'block';
            } else {
                console.error('Element with class ' + descClass + ' not found');
            }
        }
    });
});
