import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { PDFExport } from '@progress/kendo-react-pdf';
import { ReactComponent as Logo } from './aa-logo-full.svg';
import { Container } from './Container';
import "./styles.css";

const MonthlyForecastPDF = () => {
  const [forecastData, setForecastData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const pdfExportComponent = useRef(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://content.astrologyanswers.com/api/monthly-forecast/2024-05-03?token=319a261b23af5ad0eac0e16fead66216&populate=*');

        const fetchImageAsBase64 = async imageUrl => {
          try {
            const response = await fetch(imageUrl);
            const blob = await response.blob();
            const reader = new FileReader();
            return await new Promise((resolve, reject) => {
              reader.onloadend = () => resolve(reader.result);
              reader.onerror = reject;
              reader.readAsDataURL(blob);
            });
          } catch (error) {
            console.error('Error fetching image:', error);
            return null;
          }
        };

        const modifiedData = {
          ...response.data,
          data: {
            ...response.data.data,
            attributes: {
              ...response.data.data.attributes,
              monthlyForecast: await Promise.all(
                response.data.data.attributes.monthlyForecast.map(async forecastItem => {
                  let contentWithBase64 = forecastItem.Content;
                  const imageUrlRegex = /src="([^"]+)"/g;
                  let match;
                  while ((match = imageUrlRegex.exec(contentWithBase64)) !== null) {
                    const imageUrl = match[1];
                    const base64Image = await fetchImageAsBase64(imageUrl);
                    if (base64Image) {
                      contentWithBase64 = contentWithBase64.replace(match[0], `src="${base64Image}"`);
                    }
                  }
                  return {
                    ...forecastItem,
                    Content: contentWithBase64
                  };
                })
              )
            }
          }
        };
        setForecastData(modifiedData);
        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleDownloadPdf = () => {
    if (pdfExportComponent.current) {
      pdfExportComponent.current.save();
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <Container>
      <button onClick={handleDownloadPdf} className="button">Download PDF</button>
      <PDFExport ref={pdfExportComponent} paperSize="A4" margin={{ top: '1cm', right: '1cm', bottom: '1cm', left: '0cm' }}>
        <div id="container">
          <div className="first-page">
            <div className="logo">
              <Logo />
            </div>
            {forecastData && (
              <div>
                <h1>Monthly<br />Forecast</h1>
                <h3>{forecastData.data.attributes.Name}</h3>
                <div className="top-bg">
                  <img src={require('./assets/images/mf-bg.jpg')} alt="" />
                </div>
                {forecastData.data.attributes.monthlyForecast.map((forecastItem, index) => (
                  <div key={index} className="page">
                    <h3>{forecastItem.Section}</h3>
                    <div dangerouslySetInnerHTML={{ __html: forecastItem.Content }} />
                    {index !== forecastData.data.attributes.monthlyForecast.length - 1 && <div className="page-break"></div>}
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </PDFExport>
    </Container>
  );
};

export default MonthlyForecastPDF;
